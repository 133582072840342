<template>
    <div v-if="show" class="youxin-toast">{{ msg }}</div>
</template>

<script>
export default {
    name: "myToast",
    props: ["msg"],
    data(){
        return{
            show: false,
        }
    },
    created(){

    },
    mounted(){

    },
    methods:{
        showFun(){
            this.show = true;
            setTimeout(()=>{
                this.show = false;
            },2000)
        }
    }
};
</script>

<style lang="less" scoped>
    .youxin-toast{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding: 8px 12px;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 8px;
        color: #fff;
    }
</style>
<template>
    <div class="main">
        <div class="main-head">
            <div class="left">
                <img src="@/assets/img/indexLogo.png" alt="" />
                <div class="youxinlixin">
                    <span class="jihua">优信立信计划</span>
                    <span class="url">youxin-gov.cn</span>
                </div>
            </div>
            <div class="right"></div>
        </div>
        <div class="banner-box">
            <img class="banner-img" v-if="plaque" src="@/assets/images/paibian-mu.png" />
            <img class="banner-img" v-else src="@/assets/images/paibian-jin.png" />
            <div :class="['companty-ame',{'companty-ame-long':compantyName.length>18}]">{{ compantyName }}</div>
        </div>

        <div :class="['pay-wx-zfb',{'pay-wx-zfb-long':compantyName.length>17}]">
            <div class="pay">
                <div
                    @click="plaque = true"
                    :class="plaque ? 'pay-active' : 'pay-mu'"
                >
                    <div :class="plaque ? 'pay-mu-yes' : 'pay-mu-no'">
                        {{ pbType[0].tag }}
                    </div>
                    <div>&yen;{{ pbType[0].price }}</div>
                </div>
                <div
                    @click="plaque = false"
                    :class="!plaque ? 'pay-active' : 'pay-mu'"
                >
                    <div :class="!plaque ? 'pay-mu-yes' : 'pay-mu-no'">
                        {{ pbType[1].tag }}
                    </div>
                    <div>&yen;{{ pbType[1].price }}</div>
                </div>
            </div>
            <div class="pay-name">
                <div
                    class="margin10"
                    style="display: flex; justify-content: space-between"
                >
                    <div>
                        <span class="margin7">服务名称</span>
                        <span style="color: #333333">优信立信计划服务</span>
                    </div>
                    <span>支付后可开发票</span>
                </div>
                <div class="margin10">
                    <span class="margin7">加入主体名称</span>
                    <span style="color: #333333">{{ compantyName }}</span>
                </div>
            </div>
            <div class="in"></div>
            <div class="pay-select">
                <div @click="payType = true" class="zfb-wx" v-if="payObj.wxpay">
                    <div class="wx"></div>
                    <span class="mar84">微信支付</span>
                    <div style="display: flex">
                        <img
                            class="wx-active-yes"
                            v-if="payType"
                            src="@/assets/img/wx-zfb-active.png"
                            alt=""
                        />
                        <img
                            class="wx-active-yes"
                            v-else
                            src="@/assets/img/wx-zfb-no.png"
                            alt=""
                        />
                    </div>
                </div>
                <div @click="payType = false" class="zfb-wx" v-if="payObj.alipay && !$store.state.is_weixin_app">
                    <div class="zfb"></div>
                    <span class="mar84">支付宝支付</span>
                    <div style="display: flex">
                        <img
                            class="wx-active-yes"
                            v-if="!payType"
                            src="@/assets/img/wx-zfb-active.png"
                            alt=""
                        />
                        <img
                            class="wx-active-yes"
                            v-else
                            src="@/assets/img/wx-zfb-no.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <div @click="payFun()" class="to-pay">
                <span>去支付</span>
            </div>
            <div class="explain">
                <img
                    @click="explain = false"
                    v-if="explain"
                    class="radio"
                    src="@/assets/img/radio-yes.png"
                    alt=""
                />
                <img
                    @click="explain = true"
                    v-else
                    class="radio"
                    src="@/assets/img/radio-no.png"
                    alt=""
                />
                <div>
                    支付前请阅读并同意
                    <span style="color: #0057ff">《立信计划牌匾服务说明》</span>
                </div>
            </div>
        </div>
        <div class="change-address">
            <div class="change-address-title">
                <div style="display: flex; align-items: center">
                    <span>寄送地址</span>
                    <div class="kuaidi">快递包邮送达</div>
                </div>
                <span @click="changeAddressFun" class="change">修改地址></span>
            </div>
            <div class="address">{{ option.provinces + option.site }}</div>
            <div class="xx-address">
                <span>{{ option.name }}</span>
                <span class="margin7">{{ option.phone }}</span>
            </div>
        </div>
        <div class="join">
            <div class="join-title">
                <span>现在加入</span>
                <span>马上建立专属信用档案</span>
            </div>
            <div class="paibian">
                <div class="left">
                    <div class="companty-ame-ea">
                        <div class="companty-ame">{{ compantyName }}</div>
                        <img
                            class="left-img"
                            src="@/assets/images/paibian-mu.png"
                            alt=""
                        />
                    </div>
                    <div>
                        <span
                            >{{ pbType[0].name
                            }}{{ pbType[0].material_format }}</span
                        >
                        <span>{{ pbType[0].size_desc_format }}</span>
                    </div>
                </div>
                <div class="left martop14">
                    <div class="companty-ame-ea">
                        <div class="companty-ame">{{ compantyName }}</div>
                        <img
                            class="left-img"
                            src="@/assets/images/paibian-jin.png"
                            alt=""
                        />
                    </div>
                    <div>
                        <span
                            >{{ pbType[1].name
                            }}{{ pbType[1].material_format }}</span
                        >
                        <span>{{ pbType[1].size_desc_format }}</span>
                    </div>
                </div>
            </div>
        </div>
        <aboutUsC />
        <shippingC @submit="submitFun" ref="shippingRef" />
        <myToast ref="mytoast" :msg="msg" />
    </div>
</template>

<script>
import myToast from "@/components/myToast";
import shippingC from "@/components/shipping";
import aboutUsC from "@/components/aboutUs";
import {
    orderInfoApi,
    orderFormApi,
    indexApi,
    saveAddressApi,
    orderPayApi,
} from "@/api/index.js";

function onBridgeReady(option, callback) {
    WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
            appId: option.appId, //公众号ID，由商户传入
            timeStamp: option.timeStamp, //时间戳，自1970年以来的秒数
            nonceStr: option.nonceStr, //随机串
            package: option.package,
            signType: option.signType, //微信签名方式：
            paySign: option.paySign,
        },
        function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
                // 使用以上方式判断前端返回,微信团队郑重提示：
                // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                callback && callback();
            } else {
                console.log(res.err_msg);
            }
        }
    );
}
function iswxBridge() {
    if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
            document.addEventListener(
                "WeixinJSBridgeReady",
                onBridgeReady,
                false
            );
        } else if (document.attachEvent) {
            document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
            document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
    }
}
export default {
    components: {
        aboutUsC,
        shippingC,
        myToast,
    },
    data() {
        return {
            msg: "32322",
            plaque: true, //true 木质牌匾 false 金属牌匾
            compantyName: "公司/个体户名称xxxxxx",
            payType: false, //true 微信支付 false 支付宝支付
            explain: true, //立信计划牌匾服务说明
            option: {
                name: "",
                phone: "",
                provinces: "",
                site: "",
            },
            provincesName: [],
            cid: "",
            pbType: [
                {
                    id: 1,
                    material: "木质",
                    material_format: "木质材质",
                    name: "立信商户荣誉牌匾",
                    pre_img: "",
                    price: "0.00",
                    size_desc: "24cm*31cm",
                    size_desc_format: "尺寸: 24cm*31cm",
                    tag: "审核费用+木质牌匾",
                },
                {
                    id: 2,
                    material: "金属",
                    material_format: "金属材质",
                    name: "立信商户荣誉牌匾",
                    pre_img: "",
                    price: "0.00",
                    size_desc: "24cm*31cm",
                    size_desc_format: "尺寸: 24cm*31cm",
                    tag: "审核费用+金属牌匾",
                },
            ],
            payObj:{},      //当前接口下发微信和支付宝的支付的状态：true为显示
        };
    },
    created() {
        iswxBridge();
        this.cid = this.$route.params.cid;
        console.log(this.$route)
        if (this.cid) {
            if (this.$store.state.companyName) {
                this.compantyName = this.$store.state.companyName;
            } else {
                this.indexApiFun();
            }
            this.getAddressFun();
            this.getForm();    
            //判断当前是否存在succeed-表示支付后的回调
            if(this.$route.query.succeed && this.$route.query.succeed=='succeed'){
                this.orderInfoApiFun();
            }
        } else {
            this.$toast("企业ID为空");
        }
    },
    methods: {
        setlocalStorageFun(option) {
            window.localStorage.setItem(
                "address_yuixin",
                JSON.stringify(option)
            );
        },
        orderInfoApiFun() {
            let self = this;
            // console.log("323");
            orderInfoApi(self.cid).then((res) => {
                if(res.code == 200){
                    if(res.data.ispay) {
                        self.util.skipUrl('/succeed/'+self.cid,{},'replace');
                    }
                }else{
                    self.$toast(res.msg || '服务器请求错误')
                }               
            });
        },
        indexApiFun() {
            let self = this;
            indexApi(self.cid).then((res) => {
                if(res.code == 200){
                    self.compantyName = res.data.comp_name;
                    let info = res.data.shipping_address;
                    let option = {
                        name: info.name,
                        phone: info.mobile,
                        provinces:
                            info.pro_code_format +
                            info.city_code_format +
                            info.gov_code_format,
                        site: info.address,
                        provincesCode: [
                            info.pro_code,
                            info.city_code,
                            info.gov_code,
                        ],
                        provincesName: [
                            info.pro_code_format,
                            info.city_code_format,
                            info.gov_code_format,
                        ],
                    };
                    self.setlocalStorageFun(option);
                    self.$refs.shippingRef.getLocaStrogFun();   
                                        
                    let options = self.util.shareData();
                    options.link = `${location.origin}/${self.cid}`;
                    options.title = res.data.comp_name;
                    // console.log(options)
                    //微信分享触发事件
                    self.util.weixinShareFun(options);
                }else{
                    self.$toast(res.msg || '服务器请求错误')
                }                
            });
        },
        getForm() {
            let self = this,obj = { cid: self.cid };
            if(self.$route.query.version)obj.version = String(self.$route.query.version); 
            orderFormApi(obj).then((res) => {
                if(res.code == 200){
                    self.pbType = res.data.products;
                    self.payObj = res.data.pay;
                    //payType: true, //true 微信支付 false 支付宝支付
                    // if(!res.data.pay.wxpay && res.data.pay.alipay)self.payType = false;
                    if(self.$store.state.is_weixin_app)self.payType = true;
                    // console.log(self.payObj)
                }else{
                    self.$toast(res.msg || '服务器请求错误')
                }                
            });
        },
        getAddressFun() {
            let address_yuixin = window.localStorage.getItem("address_yuixin");
            if (address_yuixin) {
                this.option.name = JSON.parse(address_yuixin).name;
                this.option.phone = JSON.parse(address_yuixin).phone;
                this.option.provinces = JSON.parse(address_yuixin).provinces;
                this.option.site = JSON.parse(address_yuixin).site;
                this.provincesName = JSON.parse(address_yuixin).provincesName;
            }
        },
        changeAddressFun() {
            this.$refs.shippingRef.showFun();
        },
        submitFun(option) {
            let self = this;
            let data = {
                cid: this.cid,
                name: option.name,
                mobile: option.phone,
                pro_code: option.provincesCode[0],
                city_code: option.provincesCode[1],
                gov_code: option.provincesCode[2],
                address: option.site,
            };
            // console.log(data);
            saveAddressApi(data).then((res) => {
                if(res.code == 200){
                    self.setlocalStorageFun(option);
                    self.getAddressFun();
                    self.$toast("修改成功");
                }else{
                    self.$toast(res.msg || '服务器请求错误')
                }                
            });
        },
        go() {
            this.$router.push({
                name: "succeed",
                query: {
                    cid: this.cid,
                },
            });
        },
        payFun() {
            let self = this,data = {
                cid: self.cid,
                product_id: self.plaque ? self.pbType[0].id : self.pbType[1].id,
                sname: self.option.name,
                smobile: self.option.phone,
                spro: self.provincesName[0],
                scity: self.provincesName[1],
                sgov: self.provincesName[2],
                saddress: self.option.site,
                pay_type: !self.payType ? '3' : (self.$store.state.is_weixin_app && !self.$store.state.is_weixin_work) ? '1' : '2', //1微信 2支付宝----1微信mp 2微信h5 3支付宝h5
                quit_url: "https://youxin-gov.cn/payment/" + self.cid+"?succeed=succeed", //成功回调页面
            };
            if(self.$route.query.version)data.version = String(self.$route.query.version);
            // console.log('*************',self.$store.state.is_weixin_app)
            orderPayApi(data).then((res) => {
                if(res.code == 200){
                    //判断当前是那个支付方式调用对应的支付逻辑。1微信 2支付宝----1微信mp 2微信h5 3支付宝h5
                    if(data.pay_type == '3'){
                        let div = document.createElement("div");
                        document.body.appendChild(div);
                        div.innerHTML = res.data;
                        self.$nextTick(() => {
                            document.forms[0].submit();
                        });
                    }else if(data.pay_type == '2'){
                        if(res.data && res.data.data)location.href = res.data.data;
                    }else if(data.pay_type == '1'){
                        onBridgeReady(res.data, () => {
                            self.go();
                        });
                    }                    
                }else{
                    self.msg = res.msg;
                    self.$refs.mytoast.showFun();
                }
            });
            //代码逻辑有修改，隐藏部分下次更新删除
            // if (this.payType) {
            //     if (this.$store.state.is_weixin_app) {
            //         console.log("微信环境支付");
            //         orderPayApi(data).then((res) => {
            //             if (res.code != 200) {
            //                 this.msg = res.msg;
            //                 this.$refs.mytoast.showFun();
            //             } else {
            //                 onBridgeReady(res.data, () => {
            //                     this.go();
            //                 });
            //             }
            //         });
            //     } else {
            //         console.log("微信h5支付");
            //         orderPayApi(data).then((res) => {
            //             if (res.code != 200) {
            //                 this.msg = res.msg;
            //                 this.$refs.mytoast.showFun();
            //             }
            //             // this.go();
            //         });
            //     }
            // } else {
            //     console.log("支付宝支付");
            //     orderPayApi(data).then((res) => {
            //         if (res.code == 200) {
            //             let div = document.createElement("div");
            //             document.body.appendChild(div);
            //             div.innerHTML = res.data;
            //             this.$nextTick(() => {
            //                 document.forms[0].submit();
            //             });
            //         } else {
            //             this.msg = res.msg;
            //             this.$refs.mytoast.showFun();
            //         }
            //     });
            // }
        },
    },
};
</script>

<style lang="less" scoped>
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 375px;
    // height: 1097px;
    background: url("~@/assets/img/pay-back.png") no-repeat;
    background-size: 100% 100%;
    .main-head {
        width: 343px;
        height: 40px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .left {
            display: flex;
            img {
                width: 40px;
                height: 40px;
            }
            .youxinlixin {
                display: flex;
                flex-direction: column;
                margin-left: 6px;
                .jihua {
                    color: #333333;
                    font-size: 16px;
                    font-weight: bold;
                }
                .url {
                    color: #666666;
                    font-size: 11px;
                }
            }
        }
        .right {
            width: 180px;
            height: 35px;
            background: url("~@/assets/img/yunying.png") no-repeat;
            background-size: 100% 100%;
        }
    }

    .banner-box{
        width: 350px;
        background: url("~@/assets/img/pay-wx-zfb.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 19px;
        padding: 15px;
        box-sizing: border-box;
        position: relative;
        .banner-img{
            width: 100%;
            display: block;
        }
        .companty-ame{
            width: 100%;
            position: absolute;
            left: 0;top: 76px;
            z-index: 3;
            color: #222222;
            font-size: 13px;
            font-weight: 500;
            padding: 0 60px;
            box-sizing: border-box;
            text-align: center;
        }
        .companty-ame-long{
            top: 70px;
            font-size: 10px;
        }
    }
    .pay-wx-zfb {
        width: 350px;
        height: 278px;
        background: url("~@/assets/img/pay-wx-zfb.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 19px;
        padding: 16px 23px 30px 23px;
        box-sizing: border-box;
        .pay {
            width: calc(145px + 145px + 13px);
            height: 60px;
            display: flex;
            justify-content: space-between;
            .pay-mu {
                position: relative;
                width: 145px;
                height: 60px;
                background: url("~@/assets/img/pay.png") no-repeat;
                background-size: 100% 100%;
                color: #999999;
                font-size: 21px;
                font-weight: 500;
                padding-top: 28px;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                .pay-mu-no {
                    position: absolute;
                    top: 3px;
                    left: 5px;
                    font-size: 11px;
                    color: #ffffff;
                }
            }
            .pay-active {
                position: relative;
                width: 145px;
                height: 60px;
                background: url("~@/assets/img/pay-active.png") no-repeat;
                background-size: 100% 100%;
                color: #d32e1e;
                font-size: 21px;
                font-weight: 500;
                padding-top: 28px;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                .pay-mu-yes {
                    position: absolute;
                    top: 3px;
                    left: 5px;
                    font-size: 11px;
                    color: #d32e1e;
                }
            }
        }
        .pay-name {
            width: 100%;
            height: auto;
            font-size: 11px;
            color: #999999;
            display: flex;
            flex-direction: column;
            .margin7 {
                margin-right: 7px;
            }
            .margin10 {
                margin-top: 10px;
            }
        }
        .in {
            width: 100%;
            height: 1px;
            background: #dddddd;
            margin-top: 15px;
            margin-bottom: 15px;
        }
        .pay-select {
            width: 100%;
            height: 25px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            .zfb-wx {
                display: flex;
                align-items: center;
                font-size: 13px;
                color: #333333;
                .wx {
                    width: 25px;
                    height: 25px;
                    background: url("~@/assets/img/wx-icon.png") no-repeat;
                    background-size: 100% 100%;
                }
                .zfb {
                    width: 25px;
                    height: 25px;
                    background: url("~@/assets/img/zfb-icon.png") no-repeat;
                    background-size: 100% 100%;
                }
                .wx-active-yes {
                    width: 20px;
                    height: 20px;
                }
                .mar84 {
                    margin: 0 4px 0 8px;
                }
            }
        }
        .to-pay {
            width: 296px;
            height: 44px;
            background: url("~@/assets/img/go-pay-btn.png") no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            padding-top: 8px;
            box-sizing: border-box;
            span {
                font-size: 16px;
                color: #ffffff;
                font-weight: 700;
            }
        }
        .explain {
            display: flex;
            font-size: 11px;
            margin-top: 3px;
            align-items: center;
            justify-content: center;
            .radio {
                width: 20px;
                height: 20px;
            }
        }
    }
    .pay-wx-zfb-long{
        height: 298px;
    }
    .change-address {
        width: 350px;
        // height: 108px;
        background: url("~@/assets/img/change-address.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        padding: 15px 23px 15px 23px;
        box-sizing: border-box;
        .change-address-title {
            display: flex;
            font-size: 16px;
            color: #333333;
            font-weight: 700;
            justify-content: space-between;
            align-items: center;
            .kuaidi {
                width: 70px;
                height: 15px;
                background: #43c93e;
                border-radius: 5px;
                font-weight: 400;
                font-size: 9px;
                color: #ffffff;
                display: flex;
                justify-content: center;
                line-height: 15px;
                text-align: center;
                margin-left: 7px;
            }
            .change {
                font-size: 11px;
                color: #0057ff;
            }
        }
        .address {
            margin-top: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .xx-address {
            font-size: 12px;
            color: #444444;
            margin-top: 5px;
            .margin7 {
                margin-left: 7px;
            }
        }
    }
    .join {
        width: 350px;
        height: 281px;
        background: url("~@/assets/img/join.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 24px;
        padding: 8px 24px 18px 24px;
        box-sizing: border-box;
        margin-bottom: 25px;
        .join-title {
            display: flex;
            align-items: center;
            span {
                &:nth-child(1) {
                    color: #bd0406;
                    font-size: 13px;
                    font-weight: 700;
                }
                &:last-child {
                    color: #666666;
                    font-size: 11px;
                    margin-left: 11px;
                }
            }
        }
        .paibian {
            display: flex;
            flex-direction: column;
            margin-top: 25px;
            .left {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .companty-ame-ea {
                    position: relative;
                    width: 141px;
                    height: 95px;
                    .companty-ame {
                        box-sizing: border-box;
                        padding: 0 10px;
                        position: absolute;
                        top: 27px;
                        left: 50%;
                        transform: translateX(-50%);
                        font-size: 6px;
                        width: 100%;
                        text-align: center;
                    }
                }
                .left-img {
                    width: 141px;
                    height: 95px;
                }
                div {
                    width: 115px;
                    display: flex;
                    flex-direction: column;
                    span {
                        &:nth-child(1) {
                            font-size: 14px;
                            color: #333333;
                        }
                        &:last-child {
                            color: #999999;
                            font-size: 13px;
                        }
                    }
                }
            }
            .martop14 {
                margin-top: 14px;
            }
        }
    }
}
</style>